import React, { useState, useEffect, useRef } from 'react';
import './HomePage.css';
import logo from '../assests/icons/QM.png'; // Ensure this path is correct
import centerImage from '../assests/images/question-mark.png'; // Import the image for the center
import plusSign from '../assests/icons/plus-sign.png'; // Plus sign image

const HomePage = () => {
  const [showForm, setShowForm] = useState(false); // State to toggle the form
  const [walletAddress, setWalletAddress] = useState('');
  const formRef = useRef(null); // To track clicks outside the form
  const [isTimeUp, setIsTimeUp] = useState(true); // Set time up to true by default

  // Function to handle form visibility
  const handleLogoClick = () => {
    setShowForm(true); // Show the form when the logo is clicked
  };

  // Close form if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowForm(false); // Close form when clicked outside
      }
    };

    if (showForm) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showForm]);

  const handleSubmit = () => {
    if (!walletAddress) {
      alert("Please fill in all required fields.");
      return;
    }

    if (isTimeUp) {
      alert("Sorry, the time is up. Submissions are closed.");
      return;
    }

    // Handle form submission logic here
    console.log('Wallet Address:', walletAddress);
    alert('Submission sent!');
  };

  // Helper function to format the countdown
  const formatCountdown = () => {
    return `00:00:00`; // Always return "time up" format
  };

  return (
    <div className="home-container">
      <div className="glitch-lines">
        {/* Add glitch lines for the background effect */}
        <div className="glitch-line"></div>
        <div className="glitch-line"></div>
        <div className="glitch-line"></div>
      </div>

      <div className="heading-container">
        <h1 className="heading-text">PARABELLUM</h1>
        <img src={logo} alt="Logo" className="heading-logo" />
      </div>

      {/* Logos in the 4 corners */}
      <img src={logo} alt="Top Left Logo" className="corner-logo top-left" />
      <img src={logo} alt="Top Right Logo" className="corner-logo top-right" />
      <img src={logo} alt="Bottom Left Logo" className="corner-logo bottom-left" />
      <img src={logo} alt="Bottom Right Logo" className="corner-logo bottom-right" />

      {/* Centered image with text underneath */}
      <div className="center-content">
        <img
          src={centerImage}
          alt="Center Image"
          className="center-image"
          onClick={handleLogoClick} // Trigger form display
        />
        <p className="center-text">Click the question mark to discover the mystery</p>
      </div>

      {/* Conditionally render the form */}
      {showForm && (
        <div className="form-container glitch-form" ref={formRef}> {/* Add glitch form transition */}
          <div className="form-heading">
            READY TO DISCOVER THE MYSTERY<br />
            ending in <span style={{ color: 'blue' }}>{formatCountdown()}</span> {/* Always show 00:00:00 */}
          </div>
          <div className="form-body">
            <div className="form-section">
              <a
                href="https://x.com/parabellu_m?s=21"
                target="_blank"
                rel="noopener noreferrer"
                className="form-button"
              >
                FOLLOW
              </a>
              <div className="checkbox-plus">
                <input type="checkbox" className="checkbox" disabled />
                <img src={plusSign} alt="Plus Sign" className="plus-sign" />
              </div>
              <a
                href="https://x.com/Parabellu_m/status/1839334847715897776"
                target="_blank"
                rel="noopener noreferrer"
                className="form-button"
              >
                RT
              </a>
            </div>

            <a
              href="https://x.com/Parabellu_m/status/1839334847715897776"
              target="_blank"
              rel="noopener noreferrer"
              className="form-button1"
            >
              TAG 3 @FRIENDS
            </a>

            <input
              type="text"
              placeholder="SOLANA WALLET ADDRESS"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              className="form-input"
              required
              disabled={isTimeUp} // Disable input
            />

            <div
              className={`form-submit disabled`} 
              style={{ cursor: 'not-allowed' }}
            >
              TIME UP
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
